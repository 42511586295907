import React from "react";
import { Layout } from "../../components";
import useStudentQuery from "../../Hooks/student";
import { FiLogOut } from "react-icons/fi";
import ProfileForm from "./ProfileForm";
import "./Profile.scss";

const Profile = () => {
  const { name, logout } = useStudentQuery();

  return (
    <Layout active={"profile"}>
      <div className="page d-flex-column pad-50 g-20">
        <header className="d-flex a-center j-between w-100">
          <p>Hello {name}! Adjust your information from here:</p>
          <button className="d-flex j-center a-center" onClick={() => logout()}>
            <FiLogOut /> Logout
          </button>
        </header>
      </div>
      <ProfileForm />
    </Layout>
  );
};

export default Profile;

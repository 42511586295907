import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import { ScheduleContext } from "../AddScheduleForm";
import useNotify from "../../../../../Hooks/Notify";
import useTime from "../../../../../Hooks/Timing";
const Cell = ({ slot }) => {
  const [selected, setSelected] = useState(false);
  const { changeDays, days } = useContext(ScheduleContext);
  const { time } = useTime();
  const notify = useNotify();
  useEffect(() => {
    changeDays(selected, slot.id);
  }, [selected]);

  const handleClick = () => {
    if (days.length == 3 && !selected) {
      notify("Limit Reached", "Cannot add more", "error", 2500);
      return;
    }
    if (slot.available) {
      setSelected(!selected);
    }
  };

  return (
    <div
      className={`cell d-flex a-center j-center ${selected && "selected"} ${
        !slot.available && "disabled"
      }`}
      role="button"
      onClick={() => handleClick()}
    >
      {moment
        .utc(new Date(`03-07-2022 ${slot.time}`))
        .add(time, "hour")
        .format("hh:mm a")}
    </div>
  );
};

export default Cell;

import React, { useState } from "react";
import { returnTimeAccordingTo } from "../../../Helpers/Methods";
import useTime from "../../../Hooks/Timing";
const Timing = ({ firstTime }) => {
  const value = localStorage.getItem("timeMargin") || returnTimeAccordingTo();
  const [margin, setMargin] = useState(value);
  const { changeTime } = useTime();

  const handleClick = () => {
    localStorage.setItem("timeMargin", margin);
    window.location.reload();
  };

  return (
    <div className="content pad-20 timing d-flex-column g-10">
      <h4>Your time zone</h4>
      <div className="d-flex g-10 a-center">
        <p>GMT+</p>{" "}
        <input
          type="number"
          className="custom"
          value={margin}
          onChange={(e) => setMargin(e.target.value)}
        />
      </div>
      <div className="d-flex j-center a-center">
        {firstTime ? (
          <button className="btn btn-primary" onClick={() => handleClick()}>
            set time
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={() => changeTime(margin)}
          >
            Apply
          </button>
        )}
      </div>
    </div>
  );
};

export default Timing;

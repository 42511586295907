import React, { useState, useEffect } from "react";
import { Time, Modal, Loading } from "../../../../components";
import moment from "moment";
import useStudentQuery from "../../../../Hooks/student";
import useNotify from "../../../../Hooks/Notify";
import ReSchedule from "./Actions/ReSchedule";
import useTime from "../../../../Hooks/Timing";
const Session = ({ Paidclass }) => {
  const [cancelShow, setCancelShow] = useState(false);
  const [reScheduleShow, setReScheduleShow] = useState(false);
  const { cancelData, cancelLoading, cancelPaid, cancelSuccess, PaidQuery } =
    useStudentQuery();
  const notify = useNotify();
  const { time } = useTime();

  useEffect(() => {
    if (cancelSuccess) {
      notify("Done!", cancelData?.data.msg, "success", 2500);
      PaidQuery();
      setCancelShow(false);
    }
  }, [cancelLoading, cancelSuccess]);

  return (
    <div className="Session d-flex g-5">
      {Paidclass.canceled && (
        <div className="canceled-overlay d-flex j-center a-center">
          <p>canceled</p>
        </div>
      )}

      <div className="date d-flex-column a-center j-center">
        <span>
          {moment(Paidclass.appointment).format("dddd").toUpperCase()}
        </span>
        <span>{moment(Paidclass.appointment).format("DD").toUpperCase()}</span>
        <span>
          {moment(Paidclass.appointment).format("MMMM").toUpperCase()}
        </span>
      </div>
      {Paidclass.ptm && (
        <div className="d-flex-column a-center j-center">
          <p className="ptm">Progress Meeting</p>
        </div>
      )}
      <div className="schedule d-flex j-between a-center g-10 pad-10">
        <Time
          time={`${moment
            .utc(Paidclass.appointment)
            .add(time, "hour")
            .format("hh:mm a")} - ${moment
            .utc(Paidclass.appointment)
            .add(time + 1, "hour")
            .format("hh:mm a")}`}
        />
        {!Paidclass.canceled && (
          <div className="d-flex g-10">
            {/* <button
              className="btn btn-custom"
              onClick={() => setReScheduleShow(true)}
            >
              Re-schedule
            </button> */}
            <button
              className="btn btn-danger"
              onClick={() => setCancelShow(true)}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
      {/* Cancel Modal */}
      <Modal
        isOpen={cancelShow}
        onClose={() => setCancelShow(false)}
        title={"You're about to cancel this class"}
      >
        {cancelLoading && (
          <Loading
            loading={cancelLoading}
            message={"Cancling your class..."}
            showMessage
          />
        )}
        {!cancelLoading && (
          <>
            <p className="tx-center">
              {" "}
              Note : canceled classes cannot be re-activate{" "}
            </p>
            <div className="tx-center">
              <span>
                You will cancel the class on{" "}
                {moment(Paidclass.appointment).format("dddd")} at{" "}
                {moment
                  .utc(Paidclass.appointment)
                  .add(time, "hour")
                  .format("hh:mm a")}
              </span>
            </div>
            <div
              className="d-flex a-center j-center g-10"
              style={{ marginTop: "10px" }}
            >
              <button
                className="btn btn-danger"
                onClick={() => cancelPaid(Paidclass.id)}
              >
                {" "}
                Yes{" "}
              </button>
              <button
                className="btn btn-warning"
                onClick={() => setCancelShow(false)}
              >
                No
              </button>
            </div>
          </>
        )}
      </Modal>
      {/* Re-Schedule Modal */}
      <Modal
        isOpen={reScheduleShow}
        onClose={() => setReScheduleShow(false)}
        title={"Re-schedule your class"}
      >
        <p>Note : your new date should be in your schedule</p>
        <ReSchedule
          paid_id={Paidclass.id}
          closeModal={() => setReScheduleShow(false)}
        />
      </Modal>
    </div>
  );
};

export default Session;

import React from "react";
import moment from "moment";
const Warning = ({ op }) => {
  const specificDate = moment(op?.enddate);

  const today = moment();
  const daysDiff = specificDate.diff(today, "days");

  if (daysDiff < 3 && daysDiff > 0) {
    return (
      <div className="alert alert-warning">
        Remaining : your next payement will be after {daysDiff} days
      </div>
    );
  }
  if (daysDiff == 0) {
    return (
      <div className="alert alert-warning">
        {" "}
        The renew link was sent to your email address{" "}
      </div>
    );
  }
};

export default Warning;

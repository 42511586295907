import React, { useEffect, useState } from "react";
import Session from "./Session";
import useStudentQuery from "../../../../Hooks/student";
import { Closed, Loading } from "../../../../components";
import moment from "moment";

const UpComing = ({ op }) => {
  const { PaidQuery, dataPaid } = useStudentQuery();
  const [loading, setLoading] = useState(true);

  const specificDate = moment(op?.enddate);

  const today = moment();
  const daysDiff = specificDate.diff(today, "days");

  useEffect(() => {
    const timer = setTimeout(async () => {
      await PaidQuery();
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="content pad-30" style={{ position: "relative" }}>
      <p>Upcoming Sessions</p>
      {daysDiff < 0 && <Closed />}
      <div className="d-flex-column schedule-base g-15">
        {loading && (
          <Loading
            loading={loading}
            showMessage
            message={"Getting your upcoming classes"}
          />
        )}

        {!loading && (
          <>
            {dataPaid?.data.length ? (
              <>
                {dataPaid?.data.map((cpaid) => {
                  return <Session Paidclass={cpaid} />;
                })}
              </>
            ) : (
              <p className="tx-center">No upcoming classes now</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UpComing;

export const mainwebsite = "https://codearea.uk";
export const origin = "https://api.codearea.uk/";
//export const origin = "http://localhost:8000/";
export const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
export const ADDITION = 1;
export const ADDITION_END = 2;
export const DEFAULT_CLASS_IMAGE = "https://i.ibb.co/7VnjKJG/New-Project.jpg";
export const GBP_RATE = 39.5;
export const openLink = (target, url) => {
  const anchor = document.createElement("a");
  anchor.target = target;
  anchor.href = url;
  anchor.click();
};

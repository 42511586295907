import { useSelector, useDispatch } from "react-redux";
import { selectTime, setTime } from "../utils/TimeState";
import useNotify from "./Notify";
const useTime = () => {
  const { time } = useSelector(selectTime);
  const dispatch = useDispatch();
  const notify = useNotify();

  const changeTime = (val) => {
    localStorage.setItem("timeMargin", val);
    dispatch(
      setTime({
        time: parseInt(val),
      })
    );
    notify("Done", "Time zone updated", "success", 2000);
  };

  return {
    time,
    changeTime,
  };
};

export default useTime;

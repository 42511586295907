import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

const token = localStorage.getItem("token") || null;
const studentObject = token ? jwtDecode(token) : { student: null };

const studentSlice = createSlice({
  name: "studentSlice",
  initialState: {
    ...studentObject?.student,
  },
  reducers: {
    setStudent: (state, action) => {
      state.id = action.payload.student.id;
      state.purchase_number = action.payload.student.purchase_number;
      state.name = action.payload.student.name;
      state.teacher_name = action.payload.student.teacher_name;
    },
    setAllStudent: (state, action) => {
      state.id = action.payload.student.id;
      state.purchase_number = action.payload.student.purchase_number;
      state.name = action.payload.student.name;
      state.teacher_name = action.payload?.student?.teacher_name;
      state.parent_email = action.payload?.student?.parent_email;
      state.parent_name = action.payload?.student?.parent_name;
      state.parent_phone = action.payload?.student?.parent_phone;
      state.birthday = action.payload?.student?.birthday;
      state.teacher_name = action.payload?.student?.teacher_name;
    },
    setTeacher: (state, action) => {
      state.teacher_id = action.payload.student.teacher_id;
    },
  },
});

export const selectStudent = (state) => state.studentSlice;

export const { setStudent, setTeacher, setAllStudent } = studentSlice.actions;

export default studentSlice.reducer;

import React, { createContext, useState } from "react";
import Notification from "./Notifications";

export const NotificationContext = createContext();

const NotificationContainer = ({ children }) => {
  const [show, setShow] = useState(false);
  const [props, setProps] = useState({});

  return (
    <NotificationContext.Provider
      value={{
        Notify: (title , message, theme, duration ) => {
          setShow(true);
          setProps({ message, theme, duration, title });
          setTimeout(() => {
            setShow(false);
          }, duration);
        },
      }}
    >
      {show && <Notification {...props} />}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContainer;

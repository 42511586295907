import moment from "moment";
import "moment-timezone";

const useDiff = (time) => {
  const now = moment();
  const specificDateTime = moment(time);
  const diffInMilliseconds = specificDateTime.diff(now, "milliseconds");
  const diffInMinutes = Math.floor(diffInMilliseconds / 1000 / 60);
  const diffInHours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);
  const diffInDays = Math.floor(diffInMilliseconds / 1000 / 60 / 60 / 24);

  let ago = {
    msg: "",
    diffInMinutes,
  };

  if (diffInDays > 0) {
    ago.msg = `${diffInDays} days to start`;
    return ago;
  }

  if (diffInHours > 0) {
    ago.msg = `${diffInHours} hours and ${
      diffInMinutes - diffInHours * 60
    } minutes to start`;
    return ago;
  }

  if (diffInMinutes > 0) {
    ago.msg = `${diffInMinutes} minutes to start`;
    return ago;
  }
  if (diffInMilliseconds < 0) {
    ago.msg = `Finished`;
    return ago;
  }
  ago.msg = "Started";
  return ago;
};

export default useDiff;

import React from "react";
import StaticClassImage from "../../../assets/classImage.png";
import { FaCheck, FaClock } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import useStudentQuery from "../../../Hooks/student";
import moment from "moment";
import "moment-timezone";
import useDiff from "../../../Hooks/DiffTime";
import { Closed } from "../../../components";
const NextClass = ({ op }) => {
  const { dataPaid, isLoadingPaid } = useStudentQuery();

  const ago = useDiff(dataPaid?.data[0]?.appointment || "");

  if (!dataPaid?.data.length) {
    return (
      <div className="content pad-20 next tx-center" style={{ width: "330px" }}>
        <p>No Class</p>
      </div>
    );
  }

  const specificDate = moment(op?.enddate);

  const today = moment();
  const daysDiff = specificDate.diff(today, "days");

  return (
    <div className="content pad-20 w-content next">
      {daysDiff < 0 && <Closed extend />}

      <div className="image-con d-flex j-center a-center">
        <img src={StaticClassImage} alt="" />
      </div>
      <div className="badge d-flex-column j-center a-center">
        <span>
          <FaCheck />
        </span>
        <span>Next Class</span>
      </div>
      {dataPaid?.data[0].ptm && (
        <div
          className="badge-ptm d-flex-column j-center a-center"
          style={{ left: "30px", right: "unset" }}
        >
          <span>
            <GiProgression />
          </span>
          <span>Progress meeting</span>
        </div>
      )}
      {!isLoadingPaid && (
        <div className="next-session content pad-20 d-flex-column">
          <div className="line-one d-flex j-between a-center">
            <p style={{ fontWeight: "bold" }}>
              Day : {moment(dataPaid?.data[0].appointment).format("dddd")}
            </p>
            <div className="date d-flex-column j-center a-center">
              <span>
                {moment(dataPaid?.data[0].appointment)
                  .format("MMMM")
                  .toUpperCase()}
              </span>
              <span>
                {moment(dataPaid?.data[0].appointment)
                  .format("DD")
                  .toUpperCase()}
              </span>
            </div>
          </div>
          {!dataPaid?.data[0].canceled ? (
            <div className="line-two d-flex j-between a-center">
              <p className="d-flex j-between a-center g-5">
                <FaClock /> {ago.msg}
              </p>
              {ago.diffInMinutes > 10 ? (
                <button className="join disabled" disabled>
                  Join Now
                </button>
              ) : (
                <a
                  className="join"
                  target="_blank"
                  href={dataPaid?.data[0].meeting_url}
                >
                  Join now
                </a>
              )}
            </div>
          ) : (
            <div className="tx-center">Canceled</div>
          )}
        </div>
      )}
    </div>
  );
};

export default NextClass;

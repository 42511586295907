import React, { useState, createContext } from "react";
import CardClass from "./CardClass";
import useStudentQuery from "../../../../Hooks/student";
import { Loading, Modal } from "../../../../components";
import ClassProfile from "./ClassProfile";

export const classModalContext = createContext();
const CContainer = () => {
  const { dataSearch, isLoadingSearch } = useStudentQuery();
  const [show, setShow] = useState(false);
  const [classData, setClassData] = useState(false);
  return (
    <classModalContext.Provider
      value={{
        setClassData,
        setShow,
        classData,
      }}
    >
      <div
        className={`content ${!isLoadingSearch && "class-container"} ${
          isLoadingSearch && "d-flex j-center a-center h-200"
        }`}
      >
        {isLoadingSearch && (
          <Loading showMessage message={"Getting First Classes"} />
        )}
        {!isLoadingSearch && (
          <>
            {dataSearch?.data.length ? (
              dataSearch?.data.map((cls) => {
                return <CardClass key={cls.id} cls={cls} />;
              })
            ) : (
              <p>No classes for now</p>
            )}
          </>
        )}
        <Modal
          onClose={() => setShow(false)}
          title={`Class information`}
          isOpen={show}
        >
          <ClassProfile />
        </Modal>
      </div>
    </classModalContext.Provider>
  );
};

export default CContainer;

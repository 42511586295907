import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useStudentQuery from "../../../Hooks/student";
const SearchForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const { SearchQuery, setSearchData } = useStudentQuery();
  const Submit = (data) => {
    setSearchData(data);
    SearchQuery();
  };

  useEffect(() => {
    setSearchData({ limit: 5, sort: "DESC" });
    SearchQuery();
  }, []);

  return (
    <form onSubmit={handleSubmit(Submit)} className="pad-30 content">
      <div className="d-flex a-center j-between g-20">
        <div className="d-flex a-center g-10">
          <label htmlFor="from">From</label>
          <input type="date" {...register("from")} id="from" />
        </div>
        <div className="d-flex a-center g-10">
          <label htmlFor="to">To</label>
          <input type="date" {...register("to")} id="to" />
        </div>
        <div className="d-flex a-center">
          <input type="checkbox" {...register("canceled")} id="canceled" />
          <label htmlFor="canceled">Canceled</label>
        </div>
        <div className="d-flex a-center g-10">
          <label htmlFor="limit">Limit</label>
          <select {...register("limit")} id="limit">
            <option value="5" defaultValue>
              5
            </option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
        <div className="d-flex a-center g-10">
          <label htmlFor="sort">Sort</label>
          <select {...register("sort")} id="sort" defaultValue="DESC">
            <option value="ASC">
              ASC
            </option>
            <option value="DESC" >DESC</option>
          </select>
        </div>
        <div className="d-flex a-center j-center g-5">
          <button type="submit" className="btn btn-primary">
            Search
          </button>
          <button
            type="button"
            className="btn btn-custom"
            onClick={() => reset()}
          >
            Reset
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;

import { useQuery, useMutation } from "react-query";
import {
  getStudentSchedule,
  getStudentTeacherSchedule,
  addStudentSchedule,
  getPaidClasess,
  refreshTheToken,
  getStudentRateData,
  getStudentClassesData,
  cancelPaidClasses,
  reschedulePaidClasses,
  deletetudentSchedule,
  searchPaidClass,
  getStudentOperation,
  updateStudent,
  joinMeeting,
} from "../API/initial";
import { useSelector } from "react-redux";
import { selectStudent } from "../utils/Student/slice";
import { mainwebsite } from "../Helpers/constants";
const useStudentQuery = () => {
  const {
    id,
    name,
    purchase_number,
    teacher_name,
    parent_name,
    parent_email,
    parent_phone,
    birthday,
  } = useSelector(selectStudent);

  //---------------------- Queries -----------------------

  const cacheTime = 60000;
  const staleTime = 30000;
  let searchData = {};

  const setSearchData = (data) => {
    searchData = { ...data };
  };

  // Get student Schedule
  const {
    isLoading: isLoadingSchedule,
    error: errorSchedule,
    data: dataSchedule,
    refetch: ScheduleQuery,
  } = useQuery(["schedule", id], () => getStudentSchedule(id), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  // Get student teacher schedule
  const {
    isLoading: isLoadingTeacherSchedule,
    error: errorTeacherSchedule,
    data: dataTeacherSchedule,
    refetch: TeacherScheduleQuery,
  } = useQuery(
    ["Teacher_schedule", purchase_number],
    () => getStudentTeacherSchedule(purchase_number),
    {
      enabled: false,
      staleTime,
      cacheTime,
    }
  );

  // Get student Rate
  const {
    isLoading: isLoadingRate,
    error: errorRate,
    data: dataRate,
    refetch: RateQuery,
  } = useQuery(["Rates", id], () => getStudentRateData(id), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  // Get student paid classes
  const {
    isLoading: isLoadingPaid,
    error: errorPaid,
    data: dataPaid,
    refetch: PaidQuery,
  } = useQuery(["Paid_class", id], (data) => getPaidClasess({ ...data, id }), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  // Get student Classes Data
  const {
    isLoading: isLoadingClasses,
    error: errorClasses,
    data: dataClasses,
    refetch: ClassesQuery,
  } = useQuery(["Classes_Data", id], () => getStudentClassesData(id), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  // Search paid clasess
  const {
    isLoading: isLoadingSearch,
    error: errorSearch,
    data: dataSearch,
    refetch: SearchQuery,
  } = useQuery(["Search", id], () => searchPaidClass({ ...searchData, id }), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  // Student operations
  const {
    isLoading: isLoadingOperation,
    error: errorOperation,
    data: dataOperation,
    refetch: OperationQuery,
  } = useQuery(["Operation", id], () => getStudentOperation(id), {
    enabled: false,
    staleTime,
    cacheTime,
  });

  //---------------------- Mutations -----------------------

  // Add schedule
  const {
    mutate,
    error: addError,
    status: addStatus,
    data: addData,
    isLoading: addLoading,
    isSuccess: addSuccess,
  } = useMutation(addStudentSchedule);

  // delete schedule
  const {
    mutate: deleteSchedule,
    error: deleteError,
    status: deleteStatus,
    data: deleteData,
    isLoading: deleteLoading,
    isSuccess: deleteSuccess,
  } = useMutation(deletetudentSchedule);

  // Join
  const {
    mutate: Join,
    error: JoinError,
    data: JoinData,
    isLoading: JoinLoading,
    isSuccess: JoinSuccess,
  } = useMutation(joinMeeting);

  // Refresh token
  const {
    mutate: refreshToken,
    data: refreshData,
    isLoading: refreshLoading,
    isSuccess: refreshSuccess,
  } = useMutation(refreshTheToken);

  // Cancel paid
  const {
    mutate: cancelPaid,
    error: cancelError,
    data: cancelData,
    isLoading: cancelLoading,
    isSuccess: cancelSuccess,
  } = useMutation(cancelPaidClasses);

  // reSchedule paid
  const {
    mutate: rePaid,
    error: reError,
    isError: reIsError,
    data: reData,
    isLoading: reLoading,
    isSuccess: reSuccess,
  } = useMutation(reschedulePaidClasses);

  // reSchedule paid
  const {
    mutate: UpdateStudent,
    error: UpdateStudentError,
    isError: UpdateStudentIsError,
    data: UpdateStudentData,
    isLoading: UpdateStudentLoading,
    isSuccess: UpdateStudentSuccess,
  } = useMutation(updateStudent);

  const getStudentSchduleQuery = async () => {
    await ScheduleQuery();
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = `${mainwebsite}/login`;
  };

  return {
    isLoadingSchedule,
    errorSchedule,
    dataSchedule,
    getStudentSchduleQuery,
    name,
    purchase_number,
    isLoadingTeacherSchedule,
    errorTeacherSchedule,
    dataTeacherSchedule,
    TeacherScheduleQuery,
    id,
    mutate,
    addStatus,
    addData,
    addError,
    addLoading,
    addSuccess,
    isLoadingPaid,
    errorPaid,
    dataPaid,
    PaidQuery,
    refreshToken,
    refreshData,
    refreshLoading,
    refreshSuccess,
    teacher_name,
    logout,
    isLoadingRate,
    RateQuery,
    dataRate,
    errorRate,
    isLoadingClasses,
    errorClasses,
    dataClasses,
    ClassesQuery,
    cancelPaid,
    cancelError,
    cancelData,
    cancelLoading,
    cancelSuccess,
    rePaid,
    reError,
    reIsError,
    reData,
    reLoading,
    reSuccess,
    deleteSchedule,
    deleteError,
    deleteStatus,
    deleteData,
    deleteLoading,
    deleteSuccess,
    isLoadingSearch,
    errorSearch,
    dataSearch,
    SearchQuery,
    setSearchData,
    isLoadingOperation,
    errorOperation,
    dataOperation,
    OperationQuery,
    parent_name,
    parent_email,
    parent_phone,
    birthday,
    UpdateStudent,
    UpdateStudentError,
    UpdateStudentIsError,
    UpdateStudentData,
    UpdateStudentLoading,
    UpdateStudentSuccess,

    Join,
    JoinError,
    JoinData,
    JoinLoading,
    JoinSuccess,
  };
};

export default useStudentQuery;

import moment from "moment";
import { timeZone } from "./constants";

export const FormatToWeek = (dateStr) => {
  // Convert date string to Date object
  const date = new Date(dateStr.week_start);

  // Get the start of the week (Sunday)
  const startOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay()
  );

  // Get the end of the week (Saturday)
  const endOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 6
  );

  return `${moment(startOfWeek).format("MM/DD")} - ${moment(endOfWeek).format(
    "MM/DD"
  )}`;
};

export const returnTimeAccordingTo = () => {
  if (timeZone == "Africa/Cairo") {
    return 3;
  }
  if (timeZone == "Asia/Dubai") {
    return 4;
  }
  return 0;
};

import { Time } from "../../../../components";
import { FiTrash, FiLoader } from "react-icons/fi";
import useSchedule from "../../../../Hooks/schedule";

import moment from "moment";
import "moment-timezone";
import useStudentQuery from "../../../../Hooks/student";
import useNotify from "../../../../Hooks/Notify";
import useTime from "../../../../Hooks/Timing";
import { useEffect } from "react";
const Schedule = ({ schedule }) => {
  const {
    deleteSchedule,
    id,
    deleteLoading,
    PaidQuery,
    getStudentSchduleQuery,
    deleteSuccess,
    dataSchedule,
  } = useStudentQuery();
  const notify = useNotify();
  const { getName } = useSchedule();
  const { time } = useTime();
  const handleClick = () => {
    if (dataSchedule?.data.length > 2) {
      deleteSchedule({ student_id: id, teacher_schedule_id: schedule.id });
    } else {
      notify(
        "Error",
        "You should have at least 2 leesons per week",
        "error",
        3000
      );
    }
  };
  useEffect(() => {
    if (deleteSuccess) {
      getStudentSchduleQuery().then((pay) => {
        PaidQuery();
        notify(
          "Done",
          "Your schedule has been deleted successfully",
          "success",
          3000
        );
      });
    }
  }, [deleteLoading, deleteSuccess]);
  return (
    <>
      <div className="schedule d-flex j-between a-center g-10 pad-10">
        <p>{getName(schedule.day)}</p>
        <Time
          time={`${moment
            .utc(new Date(`03-07-2022 ${schedule.time}`))
            .add(time, "hour")
            .format("hh:mm a")} - ${moment
            .utc(new Date(`03-07-2022 ${schedule.time}`))
            .add(time + 1, "hour")
            .format("hh:mm a")}`}
        />
        <button className="setting" onClick={() => handleClick()}>
          {deleteLoading ? <FiLoader /> : <FiTrash />}
        </button>
      </div>
    </>
  );
};

export default Schedule;

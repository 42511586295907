import React from "react";
import { SquareLoader } from "react-spinners";
const Loading = ({ loading, showMessage, message }) => {
  return (
    <div className={`d-flex${showMessage && "-column g-15"} a-center j-center`}>
      <SquareLoader color="#5855D6" size={30} loading={loading} />
      {showMessage && <span>{message}</span>}
    </div>
  );
};

export default Loading;

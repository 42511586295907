import React, { useEffect, useState } from "react";
import "./Transcripts.scss";
import { Layout, Loading, Modal } from "../../components";
import useStudentQuery from "../../Hooks/student";
import { FiLogOut } from "react-icons/fi";
import moment from "moment";
import "moment-timezone";
import CurrentPayment from "./components/CurrentPayment";
import { GBP_RATE } from "../../Helpers/constants";
import useNotify from "../../Hooks/Notify";
const Transcripts = () => {
  const { name, logout, OperationQuery, dataOperation, isLoadingOperation } =
    useStudentQuery();

  const notify = useNotify();

  const [pause, setPause] = useState(false);

  useEffect(() => {
    OperationQuery();
  }, []);

  const handlePauseClick = () => {
    setPause(false);
    notify("Done", "We got your request and will contact you", "success", 2000);
  };
  return (
    <Layout active={"trans"}>
      <div className="page d-flex-column pad-50 g-20">
        <header className="d-flex a-center j-between w-100">
          <p>Hello {name} ! you can see all your payments here</p>
          <button className="d-flex j-center a-center" onClick={() => logout()}>
            <FiLogOut /> Logout
          </button>
        </header>
        {isLoadingOperation && (
          <Loading
            loading={isLoadingOperation}
            message={"Getting your payments"}
            showMessage
          />
        )}
        {!isLoadingOperation && (
          <>
            <CurrentPayment plan={dataOperation?.data[0]} />
            <div className="div course d-flex j-between">
              <h2>Payment History</h2>
              <div>
                <button onClick={() => setPause(true)}>Pause course</button>
              </div>
            </div>
            <table className="my-table">
              <thead>
                <tr>
                  <th>Chapter Title</th>
                  <th>Course Name</th>
                  <th>Plan Title</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {dataOperation?.data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.chaptertitle}</td>
                    <td>{item.coursename}</td>
                    <td>{item.plantitle}</td>
                    <td>
                      {" "}
                      {moment(item?.enddate).format("MMMM")}{" "}
                      {moment(item?.enddate).format("DD")}
                      {", "}
                      {moment(item?.enddate).format("YYYY")}
                    </td>
                    <td>{item.status}</td>
                    <td>{item.type}</td>
                    <td>
                      {parseInt(
                        parseFloat(item?.amount)
                      )}
                      EGP
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <Modal
        isOpen={pause}
        onClose={() => setPause(false)}
        title={"Are your sure?"}
      >
        <p>Are you sure you want to pause your kid course</p>
        <div
          className="d-flex a-center j-center g-10"
          style={{ marginTop: "10px" }}
        >
          <button className="btn btn-danger" onClick={() => handlePauseClick()}>
            {" "}
            Yes{" "}
          </button>
          <button className="btn btn-warning" onClick={() => setPause(false)}>
            No
          </button>
        </div>
      </Modal>
    </Layout>
  );
};

export default Transcripts;

import React from "react";
import "./Layout.scss";
import logo from "../../assets/logo.png";
import { BiMenu, BiBook, BiMoney } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";

const Layout = ({ children, active }) => {
  return (
    <div className="parent d-flex">
      <aside className="d-flex-column">
        <div className="logo d-flex j-center a-center pad-20">
          <img src={logo} alt="Logo" />
        </div>
        <ul className="d-flex-column j-center a-center">
          <li
            className={`d-flex j-center a-center ${
              active == "dash" && "active"
            }`}
          >
            <Link to={"/"} className="d-flex g-10">
              <div className="icon">
                <BiMenu />
              </div>
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={`d-flex j-center a-center ${
              active == "profile" && "active"
            }`}
          >
            <Link to={"/profile"} className="d-flex g-10">
              <div className="icon">
                <FiUser />
              </div>
              <span>Profile</span>
            </Link>
          </li>
          <li
            className={`d-flex j-center a-center ${
              active == "class" && "active"
            }`}
          >
            <Link to={"/classes"} className="d-flex g-10">
              <div className="icon">
                <BiBook />
              </div>
              <span>Classes</span>
            </Link>
          </li>
          <li
            className={`d-flex j-center a-center ${
              active == "trans" && "active"
            }`}
          >
            <Link to={"/payments"} className="d-flex g-10">
              <div className="icon">
                <BiMoney />
              </div>
              <span>Transcripts</span>
            </Link>
          </li>
        </ul>
        <div className="contact d-flex-column a-center j-center">
          service@codearea.uk
        </div>
      </aside>
      <div className="contain scroll-auto-y">{children}</div>
    </div>
  );
};

export default Layout;

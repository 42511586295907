import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { LinearScale } from "chart.js/auto";
import { Loading } from "../../../../components";
import useStudentQuery from "../../../../Hooks/student";
import { FormatToWeek } from "../../../../Helpers/Methods";
const RateChart = () => {
  const { RateQuery, dataRate, errorRate } = useStudentQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(async () => {
      await RateQuery();
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const chartData = {
    labels: dataRate?.data.map((row) => FormatToWeek(row)),
    datasets: [
      {
        label: "Assignment Rate",
        data: dataRate?.data.map((row) => row.avg_assignment_rate),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Student Focus Rate",
        data: dataRate?.data.map((row) => row.avg_st_focus_rate),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        min: 0,
      },
    },
  };

  return (
    <div className="content pad-30">
      <p> Your rates this month </p>
      {loading && (
        <Loading
          loading={loading}
          showMessage
          message={"Getting some analytics"}
        />
      )}
      {!loading && !errorRate && (
        <>
          {dataRate?.data.length > 0 ? (
            <Line data={chartData} options={options} />
          ) : (
            <div>No data available</div>
          )}
        </>
      )}
    </div>
  );
};

export default RateChart;

import { createSlice } from "@reduxjs/toolkit";
import { returnTimeAccordingTo } from "../Helpers/Methods";
const time = localStorage.getItem("timeMargin") || returnTimeAccordingTo();

const timeSlice = createSlice({
  name: "timeSlice",
  initialState: {
    time: parseInt(time),
  },
  reducers: {
    setTime: (state, action) => {
      state.time = action.payload.time;
    },
  },
});

export const selectTime = (state) => state.timeSlice;

export const { setTime } = timeSlice.actions;

export default timeSlice.reducer;

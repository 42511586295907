import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { mainwebsite } from "../Helpers/constants";
import { setStudent } from "../utils/Student/slice";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
const Token = ({ children }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams.get("token") && !localStorage.getItem("token")) {
      window.location.href = `${mainwebsite}/login`;
    }
    if (searchParams.get("token")) {
      dispatch(setStudent(jwtDecode(searchParams.get("token"))));
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, []);
  return <div>{children}</div>;
};

export default Token;

import { useContext } from "react";
import { NotificationContext } from "../components/Notification/NotificationContainer";

const useNotify = () => {
  const { Notify } = useContext(NotificationContext);

  return Notify;
};

export default useNotify;

import React, { useContext } from "react";
import { DEFAULT_CLASS_IMAGE } from "../../../../Helpers/constants";
import moment from "moment";
import "moment-timezone";
import { classModalContext } from "./CContainer";
import useTime from "../../../../Hooks/Timing";
const CardClass = ({ cls }) => {
  const { setClassData, setShow } = useContext(classModalContext);
  const { time } = useTime();
  const hanldeShow = () => {
    if (!cls.canceled) {
      setClassData({ ...cls });
      setShow(true);
    }
  };
  return (
    <div
      className="class-card"
      style={{
        "--image-url": `url(${cls.cover_url || DEFAULT_CLASS_IMAGE})`,
      }}
    >
      <div className="class-card-content">
        <div className="class-card-title">
          <small>{cls.lesson_title ? cls.lesson_title : "No Lesson yet"}</small>
        </div>
        <div className="class-card-description d-flex-column g-5">
          <span>Day : {moment(cls.appointment).format("dddd")}</span>
          <span>
            Date time : {moment(cls.appointment).format("DD/MM")} -{" "}
            {moment.utc(cls.appointment).add(time, "hour").format("hh:mm a")}{" "}
            {moment
              .utc(cls.appointment)
              .add(time + 1, "hour")
              .format("hh:mm a")}
          </span>
        </div>
        <button
          className="class-card-button"
          disabled={cls.canceled}
          onClick={() => hanldeShow()}
        >
          {cls.canceled ? "Canceled" : "More details"}
        </button>
      </div>
    </div>
  );
};

export default CardClass;

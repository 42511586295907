import React, { useState, useEffect } from "react";
import useStudentQuery from "../../Hooks/student";
import { useForm } from "react-hook-form";
import { Modal } from "../../components";
import ChangePasswordForm from "./ChangePasswordForm";
import useNotify from "../../Hooks/Notify";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setTeacher, setAllStudent } from "../../utils/Student/slice";

const ProfileForm = () => {
  const {
    id,
    name,
    parent_email,
    parent_name,
    parent_phone,
    purchase_number,
    birthday,
    UpdateStudent,
    UpdateStudentData,
    UpdateStudentLoading,
    UpdateStudentSuccess,
    refreshToken,
    refreshLoading,
    refreshSuccess,
    refreshData,
  } = useStudentQuery();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
      parent_name,
      parent_email,
      parent_phone,
      birthday,
    },
  });

  const onSubmit = (data) => {
    UpdateStudent({ ...data, id });
  };

  useEffect(() => {
    if (UpdateStudentSuccess) {
      notify("Done", UpdateStudentData?.data.msg, "success", 3000);
      refreshToken(id);
    }
  }, [UpdateStudentLoading, UpdateStudentSuccess]);
  useEffect(() => {
    if (refreshSuccess) {
      localStorage.setItem("token", refreshData?.data.token);
      const decoded = jwtDecode(refreshData?.data.token);
      dispatch(setTeacher(decoded));
      dispatch(setAllStudent(decoded));
    }
  }, [refreshLoading, refreshSuccess, refreshData]);
  return (
    <>
      <div className="form-container">
        <p className="tx-center no-margin">Contact with us : service@codearea.uk</p>
        <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
          {errors.name && <span>{errors.name.message}</span>}
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              {...register("name", { required: "Name field is required" })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="parent_name">Parent Name:</label>
            <input
              type="text"
              id="parent_name"
              name="parent_name"
              {...register("parent_name", {
                required: "parent_name field is required",
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="parent_email">Parent Email:</label>
            <input
              type="email"
              id="parent_email"
              name="parent_email"
              {...register("parent_email", {
                required: "parent_email field is required",
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="parent_phone">Parent Phone:</label>
            <input
              type="tel"
              id="parent_phone"
              name="parent_phone"
              {...register("parent_phone", {
                required: "parent_phone field is required",
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="birthday">Birthday:</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              {...register("birthday", {
                required: "birthday field is required",
              })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purchase_number">Purchase Number:</label>
            <input
              type="text"
              id="purchase_number"
              name="purchase_number"
              value={purchase_number}
              readOnly
            />
          </div>
          <button type="submit" className="btn btn-primary">
            {UpdateStudentLoading ? "Loading . . ." : "Save Changes"}
          </button>
          <button
            type="button"
            className="btn btn-custom"
            onClick={() => setShow(true)}
          >
            Change password
          </button>
        </form>
      </div>
      <Modal
        isOpen={show}
        onClose={() => setShow(false)}
        title={"Change password"}
      >
        <ChangePasswordForm closeModal={() => setShow(false)} />
      </Modal>
    </>
  );
};

export default ProfileForm;

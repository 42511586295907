const useSchedule = () => {
  const getName = (day) => {
    switch (day) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "Invalid day number";
    }
  };
  const reArrange = (sc) => {
    const saturday = [];
    const sunday = [];
    const monday = [];
    const tuesday = [];
    const wednesday = [];
    const thursday = [];
    const friday = [];

    for (let i = 0; i < sc?.length; i++) {
      switch (sc[i]?.day) {
        case 0:
          sunday.push(sc[i]);
          break;
        case 1:
          monday.push(sc[i]);
          break;
        case 2:
          tuesday.push(sc[i]);
          break;
        case 3:
          wednesday.push(sc[i]);
          break;
        case 4:
          thursday.push(sc[i]);
          break;
        case 5:
          friday.push(sc[i]);
          break;
        case 6:
          saturday.push(sc[i]);
          break;
        default:
          return "Invalid day number";
      }
    }
    return [saturday, sunday, monday, tuesday, wednesday, thursday, friday];
  };
  return { reArrange, getName };
};

export default useSchedule;

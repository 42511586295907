import useStudentQuery from "./student";
import { setTeacher, setAllStudent } from "../utils/Student/slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";

const useRefresh = () => {
  const { refreshData, refreshLoading, refreshSuccess } = useStudentQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    if (refreshSuccess) {
      localStorage.setItem("token", refreshData?.data.token);
      const decoded = jwtDecode(refreshData?.data.token);
      dispatch(setTeacher(decoded));
      dispatch(setAllStudent(decoded));
    }
  }, [refreshLoading, refreshSuccess, refreshData]);
};

export default useRefresh;

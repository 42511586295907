import axios from "axios";
import { origin, timeZone } from "../Helpers/constants";

export const getStudentTeacherSchedule = (purchase_number) => {
  return axios.get(
    `${origin}student/teacher/schedule?purchase_number=${purchase_number}`
  );
};

export const getStudentSchedule = (id) => {
  return axios.get(`${origin}student/schedule/${id}`);
};

export const joinMeeting = (data) => {
  return axios.post(`${origin}student/joinMeet`, data);
};

export const addStudentSchedule = (payload) => {
  return axios.post(`${origin}student/action_schedule`, {
    ...payload,
    add: true,
  });
};

export const getPaidClasess = (data) => {
  return axios.get(
    `${origin}student/paid_class?timeZone=${timeZone}&id=${data.id}&limit=${4}`
  );
};

export const refreshTheToken = (id) => {
  return axios.post(`${origin}student/refresh/${id}`);
};

export const getStudentRateData = (id) => {
  return axios.get(`${origin}student/rates/${id}`);
};

export const getStudentClassesData = (id) => {
  return axios.get(`${origin}student/classes_data/${id}`);
};

export const cancelPaidClasses = (id) => {
  return axios.put(`${origin}student/cancel_paid/${id}`);
};
export const reschedulePaidClasses = (data) => {
  return axios.put(`${origin}student/reschedule_paid/${data.id}`, {
    time: data.time,
    paid_id: data.paid_id,
  });
};

export const deletetudentSchedule = (payload) => {
  return axios.post(`${origin}student/action_schedule`, {
    ...payload,
  });
};

export const searchPaidClass = (data) => {
  return axios.get(
    `${origin}student/paid_class/search?data=${JSON.stringify(data)}`
  );
};

export const getStudentOperation = (id) => {
  return axios.get(`${origin}student/operation/${id}`);
};

export const updateStudent = (data) => {
  return axios.put(`${origin}student/${data.id}`, data);
};

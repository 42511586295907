import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useNotify from "../../Hooks/Notify";
import useStudentQuery from "../../Hooks/student";

const ChangePasswordForm = ({ closeModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    id,
    UpdateStudent,
    UpdateStudentData,
    UpdateStudentLoading,
    UpdateStudentSuccess,
  } = useStudentQuery();

  const notify = useNotify();

  const onSubmit = (data) => {
    if (data.password !== data.confirm_password) {
      notify("Error", "The passwords did not match", "error", 3000);
    } else {
      UpdateStudent({ ...data, id });
    }
  };
  useEffect(() => {
    if (UpdateStudentSuccess) {
      notify("Done", UpdateStudentData?.data.msg, "success", 3000);
      closeModal();
    }
  }, [UpdateStudentLoading, UpdateStudentSuccess]);
  return (
    <div className="d-flex-column">
      <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
        {errors.name && <span>{errors.name.message}</span>}
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="text"
            id="password"
            autoComplete="off"
            {...register("password", {
              required: "password field is required",
            })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirm_password">Confirm password:</label>
          <input
            type="text"
            id="confirm_password"
            autoComplete="off"
            name="confirm_password"
            {...register("confirm_password", {
              required: "confirm_password field is required",
            })}
          />
        </div>
        <button type="submit" className="btn btn-custom">
          {UpdateStudentLoading ? "Loading . . ." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;

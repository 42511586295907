import React from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import moment from "moment";
import "moment-timezone";
import { GBP_RATE } from "../../../Helpers/constants";
const CurrentPayment = ({ plan }) => {
  const isStatusActive = plan?.status === "current";

  return (
    <div className={`plan-card ${isStatusActive ? "active" : "inactive"}`}>
      <div className="plan-header">
        <div className="plan-title">{plan?.plantitle}</div>
        <div className="plan-type">{plan?.type}</div>
      </div>
      <div className="plan-body">
        <div className="plan-section">
          <div className="plan-section-title">Chapter Title</div>
          <div className="plan-section-value">{plan?.chaptertitle}</div>
        </div>
        <div className="plan-section">
          <div className="plan-section-title">Course Name</div>
          <div className="plan-section-value">{plan?.coursename}</div>
        </div>
        <div className="plan-section">
          <div className="plan-section-title">End Date</div>
          <div className="plan-section-value">
            {moment(plan?.enddate).format("MMMM")}{" "}
            {moment(plan?.enddate).format("DD")}
            {", "}
            {moment(plan?.enddate).format("YYYY")}
          </div>
        </div>
        <div className="plan-section">
          <div className="plan-section-title">Amount</div>
          <div className="plan-section-value">
            {parseInt(parseFloat(plan?.amount))} EGP
          </div>
        </div>
        <div className="plan-section">
          <div className="plan-section-title">Status</div>
          <div className="plan-section-value">
            {isStatusActive ? (
              <>
                <FaCheckCircle className="status-icon active" />
                Active
              </>
            ) : (
              <>
                <FaTimesCircle className="status-icon inactive" />
                Inactive
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPayment;

import { FiLogOut } from "react-icons/fi";
import "./Home.scss";
import { Layout, Modal } from "../../components";
import CurrentSchedule from "./components/Schedule/CurrentSchedule";
import UpComing from "./components/Classes/UpComing";
import NextClass from "./components/NextClass";
import useStudentQuery from "../../Hooks/student";
import RateChart from "./components/Charts/RateChart";
import ClassesChart from "./components/Charts/ClassesChart";
import Timing from "./components/Timing";
import { useEffect, useState } from "react";
import Warning from "./components/Warning";
const Home = () => {
  const {
    name,
    teacher_name,
    logout,
    OperationQuery,
    dataOperation,
    isLoadingOperation,
  } = useStudentQuery();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("timeMargin")) {
      setShow(true);
    }
    OperationQuery();
  }, []);

  return (
    <Layout active={"dash"}>
      <div className="contain d-flex pad-20">
        <main className="d-flex-column g-20">
          <header className="d-flex j-between a-center">
            <p>
              Hello {name} !{" "}
              {teacher_name ? `Your instructor is ${teacher_name}` : ""}
            </p>
            <button
              className="d-flex j-center a-center"
              onClick={() => logout()}
            >
              <FiLogOut /> Logout
            </button>
          </header>
          {!isLoadingOperation && dataOperation?.data.length ? (
            <Warning op={dataOperation?.data[0]} />
          ) : (
            ""
          )}
          <CurrentSchedule />
          <UpComing op={dataOperation?.data[0]} />
          <RateChart />
        </main>
        <aside className="aside pad-10 d-flex-column j-start a-center">
          <NextClass op={dataOperation?.data[0]} />
          <Timing />
        </aside>
      </div>
      <Modal
        isOpen={show}
        onClose={() => setShow(false)}
        title={"Sitting your time zone"}
        noClose
      >
        <Timing firstTime />
      </Modal>
    </Layout>
  );
};

export default Home;

import React from "react";
import "./Classes.scss";
import { Layout } from "../../components";
import useStudentQuery from "../../Hooks/student";
import { FiLogOut } from "react-icons/fi";
import SearchForm from "./components/SearchForm";
import CContainer from "./components/Results/CContainer";
const Classes = () => {
  const { name, logout } = useStudentQuery();
  return (
    <Layout active={"class"}>
      <div className="page d-flex-column pad-50 g-20">
        <header className="d-flex a-center j-between w-100">
          <p>Hello {name} ! you can see all your classes here</p>
          <button className="d-flex j-center a-center" onClick={() => logout()}>
            <FiLogOut /> Logout
          </button>
        </header>
        <SearchForm />
        <CContainer />

      </div>
    </Layout>
  );
};

export default Classes;

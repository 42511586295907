import React, { useState, useEffect } from "react";
import useStudentQuery from "../../../../../Hooks/student";
import useNotify from "../../../../../Hooks/Notify";
import useTime from "../../../../../Hooks/Timing";
import { ADDITION } from "../../../../../Helpers/constants";
import moment from "moment";
import "moment-timezone";
const ReSchedule = ({ paid_id, closeModal }) => {
  // Hooks
  const {
    dataSchedule,
    reData,
    reError,
    reIsError,
    reLoading,
    rePaid,
    reSuccess,
    id,
    PaidQuery,
  } = useStudentQuery();
  const { time } = useTime();
  const notify = useNotify();
  // State
  const [re, setRe] = useState("");

  // Variables
  const days = dataSchedule?.data.map((sc) => sc.day);
  const times = dataSchedule?.data.map((sc) => sc.time);

  // Effects
  useEffect(() => {
    if (reIsError) {
      notify("Error", reError?.response.data.msg, "error", 2500);
    }
    if (reSuccess) {
      notify("Done", reData?.data.msg, "success", 2500);
      PaidQuery();
      closeModal();
    }
  }, [reLoading, reIsError, reSuccess]);

  // Actions
  const handleSubmit = (e) => {
    e.preventDefault();
    const inDate = new Date(re);
    if (days.indexOf(inDate.getDay()) == -1 && re != "") {
      notify("Error", "This day is not in your schedule", "error", 2500);
      return;
    }
    if (days.indexOf(inDate.getDay()) > -1) {
      const dayIndex = days.indexOf(inDate.getDay());
      const time = times[dayIndex];
      const Newhours = inDate.getHours();
      const hours = new Date(`03-07-2022 ${time}`).getHours();
      if (Newhours != hours + ADDITION) {
        notify(
          "Error",
          `In this day your class should be at (${moment(`03-07-2022 ${time}`)
            .add(ADDITION, "hour")
            .format("h a")})`,
          "error",
          2500
        );
        return;
      }
    }
    rePaid({ id, time: inDate, paid_id });
  };
  return (
    <form className="d-flex g-10 a-center" onSubmit={handleSubmit}>
      <label htmlFor="date">New date</label>
      <input
        type="datetime-local"
        value={re}
        name="d"
        id="date"
        required
        onChange={(e) => setRe(e.target.value)}
      />
      <button className="btn btn-primary" type="submit" disabled={reLoading}>
        {reLoading ? "Loading" : "Submit"}
      </button>
    </form>
  );
};

export default ReSchedule;

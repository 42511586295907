import React from "react";
import "./schdeule.scss";
import Cell from "./Cell";
const ScheduleTable = ({ st, sn, mn, tu, wd, th, fr }) => {
  return (
    <div className="d-flex g-20">
      <div className="d-flex-column col">
        <span className="head">Saturday</span>
        {st?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Sunday</span>
        {sn?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Monday</span>
        {mn?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Tuesday</span>
        {tu?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Wednesday</span>
        {wd?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Thursday</span>
        {th?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
      <div className="d-flex-column col">
        <span className="head">Friday</span>
        {fr?.map((slot) => {
          return <Cell key={slot.id} slot={slot} />;
        })}
      </div>
    </div>
  );
};

export default ScheduleTable;

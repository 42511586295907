import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, Classes, Transcripts, Profile } from "./pages";
import { Token, NotificationContainer } from "./components";
import useRefresh from "./Hooks/Refresh";
import { useEffect } from "react";
import { mainwebsite } from "./Helpers/constants";
function App() {
  useEffect(() => {
    if (window.screen.availWidth < 1000) {
      window.location.href = mainwebsite;
    }
  }, []);
  useRefresh();
  return (
    <Router className="App">
      <NotificationContainer>
        <Routes>
          <Route
            path="/"
            element={
              <Token>
                <Home />
              </Token>
            }
          />
          <Route
            path="/classes"
            element={
              <Token>
                <Classes />
              </Token>
            }
          />
          <Route
            path="payments"
            element={
              <Token>
                <Transcripts />
              </Token>
            }
          />
          <Route
            path="profile"
            element={
              <Token>
                <Profile />
              </Token>
            }
          />
        </Routes>
      </NotificationContainer>
    </Router>
  );
}

export default App;

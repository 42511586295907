import React, { useContext } from "react";
import { classModalContext } from "./CContainer";
import { DEFAULT_CLASS_IMAGE } from "../../../../Helpers/constants";
import moment from "moment";
import "moment-timezone";
const ClassProfile = () => {
  const { classData } = useContext(classModalContext);
  return (
    <div className="profile d-flex-column g-10">
      <div className="lesson-card">
        <div className="lesson-image-container">
          <img
            src={classData?.cover_url || DEFAULT_CLASS_IMAGE}
            alt={classData?.lesson_title}
            className="lesson-image"
          />
          <div className="lesson-appointment">
            {moment(classData?.appointment).format("MMMM")}{" "}
            {moment(classData?.appointment).format("DD")}
            {", "}
            {moment(classData?.appointment).format("YYYY")}
          </div>
        </div>

        <div className="lesson-details">
          <h2 className="lesson-title" style={{ marginBottom: "5px" }}>
            {classData?.lesson_title}
          </h2>
          <div className="lesson-links">
            <a href={classData?.assignment_url} className="lesson-link">
              Assignments
            </a>
            <a href={classData?.quiz_url} className="lesson-link">
              {classData?.quiz_url ? "Quizzes" : "No quiz"}
            </a>
            <a href={classData?.material_url} className="lesson-link">
              Material
            </a>
          </div>
          <div className="lesson-rates">
            <div className="lesson-rate">
              <span className="lesson-rate-label">Assignment Rate:</span>
              <span className="lesson-rate-value">
                {classData?.assignment_rate}/5
              </span>
            </div>
            <div className="lesson-rate">
              <span className="lesson-rate-label">Focus Rate:</span>
              <span className="lesson-rate-value">
                {classData?.st_focus_rate}/5
              </span>
            </div>
          </div>
          <span
            className="lesson-comment"
            style={{ margin: "8px 0px", display: "block" }}
          >
            Comment : {classData?.comment ? classData?.comment : "No comment"}
          </span>
          <span
            className="lesson-custom"
            style={{ margin: "8px 0px", display: "block" }}
          >
            Teacher Assignment :{" "}
            {classData?.custom_assignment
              ? classData?.assignment
              : "No Assignment"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClassProfile;

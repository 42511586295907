import { configureStore } from "@reduxjs/toolkit";
import studentReducer from "../utils/Student/slice";
import timeReducer from "../utils/TimeState";
const store = configureStore({
  reducer: {
    studentSlice: studentReducer,
    timeSlice: timeReducer,
  },
});

export default store;

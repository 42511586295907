import React, { useState, useEffect } from "react";
import "./Notification.scss";

const Notification = ({ message, theme, duration, title }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  if (!visible) {
    return null;
  }

  return (
    <div className={`notification ${theme}`}>
      <span className="title">{title}</span>
      <span className="message">{message}</span>
    </div>
  );
};

export default Notification;

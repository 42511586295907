import React, { useEffect, createContext, useState, useCallback } from "react";
import ScheduleTable from "./ScheduleTable";
import useStudentQuery from "../../../../Hooks/student";
import useSchedule from "../../../../Hooks/schedule";
import useNotify from "../../../../Hooks/Notify";
import { Loading } from "../../../../components";
import { timeZone } from "../../../../Helpers/constants";

export const ScheduleContext = createContext();

const AddScheduleForm = ({ CloseModal }) => {
  const {
    TeacherScheduleQuery,
    dataTeacherSchedule,
    isLoadingTeacherSchedule,
    id,
    mutate: addSchedule,
    addLoading,
    getStudentSchduleQuery,
    addSuccess,
    dataSchedule,
    PaidQuery,
  } = useStudentQuery();

  useEffect(() => {
    TeacherScheduleQuery();
  }, []);

  const { reArrange } = useSchedule();
  const memoizedReArrange = useCallback(
    () => reArrange(dataTeacherSchedule?.data),
    [isLoadingTeacherSchedule]
  );

  const [st, sn, mn, tu, wd, th, fr] = memoizedReArrange();

  const notify = useNotify();
  const [days, setDays] = useState([...dataSchedule?.data]);

  const handleAddSchedule = async () => {
    if (days.length < 2) {
      notify("Not enough", "Minimum classes number is 2", "error", 2500);
      return;
    } else {
      const selected = days.filter((day) => !isNaN(day));
      // submit Classes
      addSchedule({
        student_id: id,
        selectedSchedule: selected,
        timeZone: timeZone,
      });
    }
  };

  useEffect(() => {
    if (addSuccess) {
      getStudentSchduleQuery();
      PaidQuery();
      notify(
        "Done",
        "Your schedule has been added successfully",
        "success",
        3000
      );
      CloseModal();
    }
  }, [addSuccess, addLoading]);
  return (
    <ScheduleContext.Provider
      value={{
        days,
        changeDays: (add, slot) => {
          if (add) {
            console.log(days);
            if (days.indexOf(slot) == -1) {
              setDays([...days, slot]);
            }
          } else {
            const slots = [...days];
            const indexToRemove = slots.indexOf(slot);
            slots.splice(indexToRemove, 1);
            setDays([...slots]);
          }
        },
      }}
    >
      <div>
        {isLoadingTeacherSchedule && (
          <Loading
            loading={isLoadingTeacherSchedule}
            showMessage
            message={"Getting schedule for your teacher"}
          />
        )}
        {addLoading && (
          <Loading
            loading={addLoading}
            showMessage
            message={"Adding your schedule..."}
          />
        )}
        {!isLoadingTeacherSchedule && !addLoading && (
          <ScheduleTable
            st={st}
            fr={fr}
            mn={mn}
            sn={sn}
            th={th}
            tu={tu}
            wd={wd}
          />
        )}
      </div>
      <div className="d-flex a-center j-center m-10">
        <button className="btn btn-primary" onClick={() => handleAddSchedule()}>
          Submit
        </button>
      </div>
    </ScheduleContext.Provider>
  );
};

export default AddScheduleForm;

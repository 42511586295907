import React from "react";

const Closed = ({ extend }) => {
  return (
    <div
      className="closed-sessions d-flex a-center j-center"
      style={{ height: extend ? "118%" : "100%" }}
    >
      <img
        src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678129-lock-512.png"
        alt=""
      />
    </div>
  );
};

export default Closed;
import React, { useEffect, useState } from "react";
import Schedule from "./Schedule";
import { FiPlus } from "react-icons/fi";
import useStudentQuery from "../../../../Hooks/student";
import { Modal, Loading } from "../../../../components";
import AddScheduleForm from "./AddScheduleForm";
const CurrentSchedule = () => {
  const { dataSchedule, getStudentSchduleQuery } = useStudentQuery();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(async () => {
      await getStudentSchduleQuery();
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="content pad-20">
      <p>Current schedule</p>
      <div className="d-flex schedule-base g-15 j-around a-center">
        {loading && (
          <Loading
            loading={loading}
            showMessage
            message={"Getting your schedule"}
          />
        )}
        {!loading && (
          <>
            {dataSchedule?.data.length ? (
              <>
                {dataSchedule?.data.map((schedule) => {
                 return <Schedule key={schedule.id} schedule={schedule} />;
                })}
              </>
            ) : (
              <p className="m-3">No Schdeule for now</p>
            )}
          </>
        )}
      </div>
      <button className="add" onClick={() => setShow(true)}>
        <FiPlus />
      </button>
      <Modal
        isOpen={show}
        onClose={() => setShow(false)}
        title={"Teacher Schedule"}
      >
        <AddScheduleForm CloseModal={() => setShow(false)}/>
      </Modal>
    </div>
  );
};

export default CurrentSchedule;
